import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Bio from "../components/bio"
import { StaticImage } from "gatsby-plugin-image"

const AboutPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title
  const cutoffScore = data.site.siteMetadata.cutoffScore

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="About Me" />
      <Bio />
      <div key="main div">
      <div className="flexcontainer">
      <h1 style={{ margin: "revert"}}>About</h1>
      <StaticImage
        className="right-icon"
        placeholder="none"
        layout="fixed"
        formats={["auto", "webp", "avif"]}
        src="../images/about.png"
        width={50}
        height={50}
        quality={50}
        alt="Course icon"
      />
      </div>
      <p>I'm an incredibly happy married man, practically thrown (lovingly) out of the door most days by my&nbsp;
      <a href={'https://www.instagram.com/prolificliving'} style={{textDecoration: "underline"}}>amazing wife</a>&nbsp;
      to play as much golf as possible. These entries are either the significant rounds I play at my normal club -or- all the rounds I play elsewhere.
      If I am not traveling, then I am pretty much playing at Old Chatham.
      </p>
      <p>The color scheme on the front page (red/green) are based on whether those rounds beat my self-determined "good round" score, 
        which right now is set to: <span style={{fontSize: "120%", fontWeight: "bold"}}>{cutoffScore}</span></p>
      <p>
        There are also some golf-adjacent thoughts under the <a href={`/tags/not-golf/`} style={{textDecoration: "underline"}}>not-golf</a> tag.
      </p>
      </div>
      <h2>Rating System</h2>
        <table className="courseInfo">
          <tbody>
        <tr>
          <td className="startable">⭐⭐⭐⭐⭐</td>
          <td>World Class</td>
        </tr>
        <tr>
          <td className="startable">⭐⭐⭐⭐</td>
          <td>A great course, I'll go out of my way to play it.</td>
        </tr>
        <tr>
        <td className="startable">⭐⭐⭐</td>
          <td>Good, would definitely return.</td>
        </tr>
        <tr>
          <td className="startable">⭐⭐</td>
          <td>Lacking, it's golf I guess.</td>
        </tr>
        <tr>
          <td className="startable">⭐</td>
          <td>Awful, would rather not even play.</td>
        </tr>
        </tbody>
      </table>

      
    </Layout>
  )
}

export default AboutPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        cutoffScore
      }
    }
  }
`
